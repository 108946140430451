<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="1200px"
          persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        {{getTitle}}
        <v-spacer/>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">
        <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
          <v-row>
            <v-col cols="12" class="">
              <v-card class="px-3">
                <v-card-text class="subtitle-1 font-weight-light">
                  <v-row>
                    <v-col cols="12" md="12">
                      <strong>Cliente: </strong> {{item.nome_idCliente}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Mês de Referência: </strong> {{item.mesRef}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Vencimento: </strong> {{item.dtVenc | moment('DD/MM/YYYY')}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Valor (R$): </strong> {{item.valor}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Multa (R$): </strong> {{item.valMulta}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Juros Mora (R$): </strong> {{item.valJuros}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Total Encargos (R$): </strong> {{item.valEncargos}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-caption">
                      Memória de calculo:
                      <v-divider/>
                      Multa de {{item.perMulta}}% (R$ {{item.valMulta}}) + Juros de {{item.perJuros}}% ao dia ({{item.totPerJuros}}% = R$ {{item.valJuros}}) - desconto R$ {{item.valorDesconto}}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col  cols="12" md="7" class="">
              <v-card class="px-3">
                <v-card-text class="subtitle-1 font-weight-light">
                  <v-row>
                    <v-col cols="12" md="4">
                      <lookup-dominio label="* Forma de Pagamento"
                                      hint="Selecione a forma de pagamento"
                                      v-model="item.formaPgto"
                                      :type="lookups.arrFormaPgto"
                                      :hidden-clear="true"
                                      :rules="[
                                          this.$validators.string.required
                                      ]"/>
                    </v-col>                    
                    <v-col cols="12" md="4">
                      <v-text-field dense
                            label="Desconto"
                            prefix="R$"
                            :rules="[
                              v => this.$validators.number.greaterThanOrEquals(v, 0)
                            ]"
                            v-model="item.valorDesconto" type="number"/>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field dense
                            label="Valor"
                            prefix="R$"
                            :rules="[
                              this.$validators.number.required,
                              v => this.$validators.number.greaterThanOrEquals(v, 0)
                            ]"
                            v-model="item.valorRecebido" type="number"/>
                    </v-col>
                    <v-col cols="12" md="4" class="text-md-right">
                      <strong>c/ encargos (R$): </strong> {{item.valorComAtraso}}
                    </v-col>
                    <v-col cols="12" md="4" class="text-md-right">
                      <strong>Valor Final (R$): </strong> {{valorFinalComDesconto}}
                    </v-col>
                    <v-col cols="12" md="4" class="text-md-right">
                      <strong :class="corDiferenca">{{diferenca}}</strong> 
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" class="">
              <v-card class="px-3">
                <v-card-title>
                  <v-icon color="red" left >mdi-bell-badge-outline</v-icon>Observação
                </v-card-title>
                <v-card-text class="subtitle-1 font-weight-light">
                  <v-textarea rows="2" label="" filled
                              :rules="[
                                  v => this.$validators.string.greaterThanOrEquals(v, 0),
                                  v => this.$validators.string.lessThanOrEquals(v, 1000),
                                ]" v-model="item.observacao"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="$emit('input', false)">Fechar</v-btn>
        <v-btn color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LookupDominio from "../../../components/shared/common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import {baixaManual} from "../../../api/faturas";
import {ApiError} from "../../../api/core";

export default {
  name: "BaixaManualFaturasDialog",
  components: {LookupDominio},
  props: ['value','item'],
  data () {
    return {
      lookups: {
        arrFormaPgto: ItemDeDominio.ARR_FORMA_PGTO,
      },
      formValid: false,
      corDiferenca: "verde",
    }
  },
  computed: {
    getTitle() {
      return 'Baixa Fatura: '+ this.item?.descricao + ' - '+ this.item?.nome_idCliente;
    },
    valorFinalComDesconto(){
      return (this.item.valorComAtraso - this.item.valorDesconto).toFixed(2);
    },
    diferenca(){
      this.corDiferenca = "verde";
      let valorInformado = parseFloat(this.item.valorRecebido);
      if (valorInformado > this.valorFinalComDesconto){
        return "Valor a mais: R$ " + (valorInformado - this.valorFinalComDesconto).toFixed(2);
      } else if (valorInformado < this.valorFinalComDesconto){
        this.corDiferenca = "vermelho";
        return "Falta: R$ " + (this.valorFinalComDesconto - valorInformado).toFixed(2);
      } else {
        return "";
      }
    }
  },
  methods: {
    async save() {
      try {
        if(this.$refs.form.validate()) {
          await baixaManual(this.item.id, {
                    valorDesconto: this.item.valorDesconto,
                    formaPgto: this.item.formaPgto,
                    valorRecebido: this.item.valorRecebido,
                    observacao: this.item.observacao,
                    documeto: 1
                  });
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Baixa efetuada com sucesso.`, {
            timeout: 3000
          })
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível baixar a fatura.`, {
            timeout: 6000
          })
        }
      }
    }
  },
}
</script>

<style scoped>
  .verde {
    color: green;
  }
  .vermelho {
    color: red;
  }
</style>