<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
        
    <ver-comprovante-fatura-dialog :item="item" v-model="verComprovanteFaturaDialog" />

    <baixa-manual-faturas-dialog :item="item" v-model="baixaManualDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <estornar-faturas-dialog :item="item" v-model="estornarDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <create-edit-faturas-dialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <VerObservacaoPagamentoDialog :item="item" v-model="verObservacaoPagamentoDialog"/>

    <div>
      <v-progress-linear
        indeterminate
        color="cyan"
        v-show="mostraLoadBoleto"
      ></v-progress-linear>
      <faturas-data-table ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                             @click:estorna="estornar"
                             @click:baixaManual="baixarManualmente"
                             @click:viewBoleto="verBoleto"
                             @click:viewRecibo="verRecibo"
                             @click:viewComprovante="verComprovanteFatura"
                             @click:viewObservacaoPagamento="verObservacaoPagamento"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import FaturasDataTable from "../../../components/shared/datatables/FaturasDataTable";
import BaseView from "../../BaseView";
import {remove} from "../../../api/faturas";
import CreateEditFaturasDialog from "../../../components/shared/dialogs/CreateEditFaturasDialog";
import {findAll as findAllCliente} from '../../../api/clientes';
import {findAll as findAllEventos} from '../../../api/eventos';
import {findAll as findAllLocal} from '../../../api/locais';
import {ItemDeDominio} from "../../../api/itemsDominio";
import {mapActions, mapGetters} from "vuex";
import {ApiError} from "../../../api/core";
import VerComprovanteFaturaDialog from "./../components/VerComprovanteFaturaDialog"
import VerObservacaoPagamentoDialog from "./../components/VerObservacaoPagamentoDialog"
import BaixaManualFaturasDialog from "./../components/BaixaManualFaturasDialog"
import EstornarFaturasDialog from "./../components/EstornarFaturasDialog"

import * as moment from 'moment';
import {downloadRecibo, downloadBoleto} from "../../../api/relatorios";

export default {
  name: "index",
  components: {BaixaManualFaturasDialog,EstornarFaturasDialog,
              CreateEditFaturasDialog, BaseView, FaturasDataTable, VerComprovanteFaturaDialog, VerObservacaoPagamentoDialog},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      baixaManualDialogOpened: false,
      estornarDialogOpened: false,
      verComprovanteFaturaDialog: false,
      verObservacaoPagamentoDialog: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
        {
          group: 'Período',
          columns: [
            {
              name: 'De',
              column: 'dtInicio',
              type: 'date',
              defaultValue: moment().startOf('month').format('YYYY-MM-DD'),
            },
            {
              name: 'Até',
              column: 'dtFim',
              type: 'date',
              defaultValue: moment().endOf('month').format('YYYY-MM-DD'),
            },
          ]
        },
        {
          name: 'Descrição',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'Tipo Fatura',
          column: 'tipoFatura',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.ARR_TIPO_FATURA
          })
        },        
        {
          name: 'Comp. Enviado',
          column: 'comprovanteEnviado',
          type: 'select',
          optionsFactory: () => ([
            {
              key: 'S',
              valor: 'Fatura comprovante enviado'
            },
          ])
        },
        {
          name: 'Remessa',
          column: 'remessa',
          type: 'select',
          optionsFactory: () => ([
            {
              key: 'S',
              valor: 'Sem Gerar Remessa'
            },
            {
              key: 'C',
              valor: 'Remessa Gerada'
            }
          ])
        },
        {
          name: 'Nosso Número',
          column: 'id',
          type: 'number'
        },
        {
          name: 'Local',
          column: 'idLocal',
          type: 'autocomplete',
          optionsFactory: (search) => findAllLocal(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })

        },
        {
          name: 'Competição',
          column: 'idEvento',
          type: 'autocomplete',
          optionsFactory: (search) => findAllEventos(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },
        {
          name: 'Status',
          column: 'status',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STS_PAGAMENTO
          })
        },
      ],
      mostraLoadBoleto: false,
    }
  },
  methods: {
    closeDialog(eu){
      this.$refs.dataTable.refresh(); 
      //this.$refs[eu].close();
    },
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.editDialogOpened = true;
    },
    verComprovanteFatura(item){
      this.item = JSON.parse(JSON.stringify(item));
      this.verComprovanteFaturaDialog = true;
    },
    verObservacaoPagamento(item){
      console.log(item);
      this.item = JSON.parse(JSON.stringify(item));
      this.verObservacaoPagamentoDialog = true;
    },
    async verBoleto(item) {
      item.formatoSaida = "pdf";
      this.mostraLoadBoleto = true;
      await downloadBoleto(item);
      this.mostraLoadBoleto = false;
    },
    async verRecibo(item) {
      item.formatoSaida = "pdf";
      await downloadRecibo(item);
    },
    baixarManualmente(item){
      this.item = JSON.parse(JSON.stringify(item));
      this.baixaManualDialogOpened = true;
    },
    estornar(item){
      this.item = JSON.parse(JSON.stringify(item));
      this.estornarDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Fatura removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível remover a Fatura.`, {
            timeout: 6000
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>