<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="600px"
          persistent>
  <v-card class="d-flex flex-column">
    <v-card-title>
      {{getTitle}}
      <v-spacer/>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">
     {{item.observacao}}
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn outlined color="primary" @click="$emit('input', false)">Fechar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

export default {
  name: "VerObservacaoPagamentoDialog",
  props: ['value','item'],
  data () {
    return {
      formValid: false
    }
  },
  computed: {
    getTitle() {
      return 'Obs. Pag.: '+ this.item?.descricao + ' - '+ this.item?.nome_idCliente;
    }
  },
  methods: {
    
  },
}
</script>

<style scoped>

</style>