<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="800px"
          persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        {{getTitle}}
        <v-spacer/>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">
        <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
          <v-row>
            <v-col cols="12" class="">
              <v-card class="px-3">
                <v-card-text class="subtitle-1 font-weight-light">
                  <v-row>
                    <v-col cols="12" md="12">
                      <strong>Cliente: </strong> {{item.nome_idCliente}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Mês de Referência: </strong> {{item.mesRef}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Vencimento: </strong> {{item.dtVenc | moment('DD/MM/YYYY')}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Valor (R$): </strong> {{item.valor}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Multa (R$): </strong> {{item.valMulta}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Juros Mora (R$): </strong> {{item.valJuros}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Encargos (R$): </strong> {{item.valEncargos}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Valor com encargos (R$): </strong> {{item.valorComAtraso}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Desconto (R$): </strong> {{item.valorDesconto}}
                    </v-col>
                    <v-col cols="12" md="4">
                      <strong>Valor Final (R$): </strong> {{valorFinalComDesconto}}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="">
              <v-card class="px-3">
                <v-card-text class="subtitle-1 font-weight-light">
                  <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="item.motivoEstorno" label="Informe o Motivo do Estorno" rows="2"/>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="$emit('input', false)">Fechar</v-btn>
        <v-btn color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LookupDominio from "../../../components/shared/common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import {estornar} from "../../../api/faturas";
import {ApiError} from "../../../api/core";

export default {
  name: "EstornarFaturasDialog",
  components: {LookupDominio},
  props: ['value','item'],
  data () {
    return {
      lookups: {
        arrFormaPgto: ItemDeDominio.ARR_FORMA_PGTO,
      },
      formValid: false,
      corDiferenca: "verde",
    }
  },
  computed: {
    getTitle() {
      return 'Estornar Fatura: '+ this.item?.descricao + ' - '+ this.item?.nome_idCliente;
    },
    valorFinalComDesconto(){
      return (this.item.valorComAtraso - this.item.valorDesconto).toFixed(2);
    },
  },
  methods: {
    async save() {
      try {
        if(this.$refs.form.validate()) {
          await estornar(this.item.id, {
                    motivoEstorno: this.item.motivoEstorno
                });
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Estorno efetuado com sucesso.`, {
            timeout: 3000
          })
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível estornar a fatura.`, {
            timeout: 6000
          })
        }
      }
    }
  },
}
</script>

<style scoped>
  .verde {
    color: green;
  }
  .vermelho {
    color: red;
  }
</style>